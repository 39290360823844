/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { Tabbar } from "@/component"
import { Button, Popup, Toast, NumberKeyboard, Modal, Input } from 'antd-mobile'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { post } from "@/service"
import moment from "moment"
import { DelNum } from "@/tool"
import "./styles.less"

const supportEmail = "renrenpaymoney"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [amount, setAmount] = useState("")
  const [amount2, setAmount2] = useState("100")
  const [data, setData] = useState(null)
  const [data2, setData2] = useState(null)
  const [amountUSDT, setAmountUSDT] = useState(0)
  const [list, setList] = useState(null)
  const [assets, setAssets] = useState([])
  const [assetIndex, setAssetIndex] = useState(0)
  const [reload, setReload] = useState(false)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [openCardFee, setOpenCardFee] = useState('-')
  const [usdtPrice, setUsdtPrice] = useState('-')
  const [emailVisible, setEmailVisible] = useState(false)
  const [menuIndex, setMenuIndex] = useState(0)
  const [info, setInfo] = useState(null)
  const [transferVisible, setTransferVisible] = useState(false)
  const [transferEmail, setTransferEmail] = useState("")
  const [transferNumber, setTransferNumber] = useState("")
  const [selfNumber, setSelfNumber] = useState("")
  const [transferAmount, setTransferAmount] = useState("")
  const [withdrawCode, setWithdrawCode] = useState("")
  const timerID = useRef()
  const [timeOut, setTimeOut] = useState(0)
  const [parssword, setPassword] = useState("")
  const [cardFeeOpen, setCardFeeOpen] = useState(false)

  useEffect(() => {
    sessionStorage.setItem("startTag", "card")
  }, [])

  const explains = [{
    number: '$408',
    // number: '$**',
    text: t("开卡费")
  },{
    number: '2.5%',
    // number: '**%',
    text: t("消费手续费")
  },{
    number: '3.5%',
    // number: '$**',
    text: t("ATM取现")
  },{
    number: '1%',
    // number: '**%',
    text: t("充值手續費")
  },{
    number: '$10,000',
    // number: '$**',
    text: t("单月充值限额")
  },{
    number: '$100,000',
    // number: '$**',
    text: t("单笔消费限额")
  }]

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("card/rules")
        Toast.clear()
        if (!!resp) {
          setOpenCardFee(resp.openCardFee)
          setUsdtPrice(resp.usdtPrice)
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/card/applyOrder")
        Toast.clear()
        const _resp = !!resp && resp.length > 0 ? resp[0] : {}
        setData(_resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [reload])

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/card/status")
        Toast.clear()
        if (!!resp) {
          if (resp.length > 0) {
            setAmountUSDT(resp[0].info.amount) 
            setData2(resp[0])
          } else {
            setData2({})
          }
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [reload])

  useEffect(() => {
    async function getList() {
      try {
        const resp = await post("/card/transaction/transactions", {
          "page": 1,
          "pageCount": 500
        })
        if (!!resp) {
          const _content = !!resp.content && resp.content.length > 0 ? resp.content : []
          setList(_content)
          // const __content = _content.filter(obj => obj.billType !== "Pre-Authorization")
          // const keys = new Set()
          // const ___content = __content.filter(obj => {
          //   const key = obj.amount + obj.desc;
          //   if (!keys.has(key)) {
          //     keys.add(key)
          //     return true
          //   } else if (obj.status === 'COMPLETED') {
          //     return true
          //   } else {
          //     return false
          //   }
          // })
          // setList(___content)
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getList()
  }, [reload])

  useEffect(() => {
    async function getAssets() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/account/info")
        if (!!resp) {
          setInfo(resp)
          const accounts = resp.accounts
          // const AccountUSD = accounts.find(account => account.asset === "USD")
          // AccountUSD.openCardFee = 5
          // AccountUSD.minRecharge = 10
          // AccountUSD.price = 1
          const AccountUSDT = accounts.find(account => account.asset === "USDT")
          AccountUSDT.openCardFee = Math.ceil(5 / parseFloat(usdtPrice) * 100) / 100
          AccountUSDT.minRecharge = Math.ceil(10 / parseFloat(usdtPrice) * 100) / 100
          AccountUSDT.price = parseFloat(usdtPrice)
          const _assets = [AccountUSDT]
          setAssets(_assets)
        }
        Toast.clear()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    if (visible) {
      getAssets() 
    } else {
      getAssets() 
      setAmount("100")
      setKeyboardVisible(false)
      setAssetIndex(0)
      setAmount2("100")
      setMenuIndex(0)
    }
  }, [visible, usdtPrice])

  async function toPay() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      await post("/card/transaction/deposit", {
        "amount": `${Math.ceil(parseFloat(menuIndex < 3 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100}`,
        "cardId": data.cardId,
        "asset": assets[assetIndex].asset
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: t('充值请求提交成功'),
      })
      setVisible(false)
      setTimeout(() => {
        setReload(!reload)
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function countDecimalPlaces() {
    const decimalRegex = /\.(\d+)/
    const match = decimalRegex.exec(amount)
    if (match) {
      return match[1].length
    } else {
      return 0
    }
  }

  async function onTransfer() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      await post("/card/transaction/transfer", {
        "amount": transferAmount,
        "fromCardNumber": selfNumber,
        "toCardNumber": transferNumber,
        "toEmail": transferEmail,
        "password": parssword,
        "verifyCode": withdrawCode
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: t("转账成功"),
      })
      setTransferVisible(false)
      setTimeout(() => {
        setReload(!reload)
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  async function toCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const resp = await post("app/captcha/needCaptcha", {
        email: localStorage.getItem("email")
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha(type).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
              sendVerifyCode({
                captchaType: type,
                tencentRandstr: null,
                tencentTicket: null,
                googleResponse: token
              })
            })
          })
        }
      } else {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: null,
          tencentTicket: null,
          googleResponse: null
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function initTencentCaptcha(type) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        }) 
      }
    })
  }

  async function sendVerifyCode(result) {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      result["email"] = localStorage.getItem("email")
      result["tag"] = "TRANSFER"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: t("成功")
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  const onDeduct = async () => {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      await post("/card/transaction/deductOpenCardFee")
      let resp
      do {
        resp = await post("/app/account/info")
        if (resp?.cardFeeStatus !== "PAID") {
          await new Promise(resolve => setTimeout(resolve, 2000))
        }
      } while (resp?.cardFeeStatus !== "PAID")
      Toast.show({
        icon: "success",
        content: t("支付成功"),
      })
      await new Promise(resolve => setTimeout(resolve, 1000))
      history.push('/apply')
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }
  
  return (
    !!data && !!data2 ? <div className="card-page">
      <div className="navbar">
        <p className="title">{t("金猴卡")}</p>
        {
          data.status === 'PASSED' && data2.status === "ACTIVATING" && <p className="t1" onClick={() => history.push('/cardInfo')}>{t("查看卡信息")}＞</p>
        }
      </div>
      <img src={IMAGE.cardImage} alt="" className="card-image" />
      {
        data.status === 'PASSED' && data2.status === "ACTIVATING" ? <>
          <div className="balance-div">
            <div className="available-div">
              <p className="t2">{t("余额")}</p>
              <p className="balance">{`$${DelNum(amountUSDT)}`}</p>
            </div>
            <div className="icons">
              <img src={ IMAGE.addFunds } alt="" onClick={() => setVisible(true)} />
              <img src={ IMAGE.transfer } alt="" onClick={() => setTransferVisible(true)} />
            </div>
          </div>
        </> : <>
          {
            data.status === 'PASSED' && data2.status === "CREATED" ? (
              <div className="result2">
                <img src={IMAGE.result3} alt="" />
                <p className="result2_t1">{ t("已申請訂購") }</p>
                <p className="result2_t2">{ t("收到實體卡后即可激活") }</p>
              </div>
            ) : (
              <div className="explain-list">
              {
                explains.map((item, index) => {
                  return <div key={index} className="explain-item">
                    <img src={IMAGE[`card_icon_${index + 1}`]} alt="" />
                    <div>
                      {/* <p className="number">{item.number === '-' ? `$${openCardFee}` : item.number}{
                        index === 0 && openCardFee !== '-' && <span>({t("限时特惠")})</span>
                      }</p> */}
                      <p className="number">{item.number}</p>
                      <p className="text">{item.text}</p>
                    </div>
                  </div>
                })
              }
              </div>
            )
          }
          
          <p className="tip">{ openCardFee !== '-' && parseFloat(openCardFee) === 0 ? t("请确保您的账户余额不少于10USDT") : t("温馨提示2", { openCardFee: openCardFee }) }</p>
          {
            // !!info && (
            //   <Button className="activation-button" disabled={
            //     (info?.securityAuthLevel < 3 && (info?.lv3Status === "IN_REVIEW" || info?.lv3Status === "SUPPLIER_IN_REVIEW")) ||
            //     data?.status === "CREATED"
            //   } onClick={() => {
            //     if (info?.securityAuthLevel < 3) {
            //       if (info?.cardFeeStatus === "NOT_PAID") {
            //         setCardFeeOpen(true)
            //       } else {
            //         history.push('/apply')
            //       }
            //     } else if (!!info?.hasPaymentPassword) {
            //       history.push("/managerInfo")
            //     } else {
            //       history.push("/managerPin")
            //     }
            //   }}>{
            //     info?.securityAuthLevel < 3 && !info?.lv3Status ? 
            //       t("申请KYC") 
            //     : info?.securityAuthLevel < 3 && (info?.lv3Status === "IN_REVIEW" || info?.lv3Status === "SUPPLIER_IN_REVIEW") ?
            //       t("KYC审核中...")
            //     : info?.securityAuthLevel < 3 && info?.lv3Status === "REJECTED" ?
            //       t("KYC审核不通过, 请重新申请")
            //     : data?.status === "CREATED" ?
            //       t("申请开卡审核中...")
            //     : data.status === "REJECTED" ?
            //       t("申请开卡不通过, 请重新申请")
            //     : t("申请开卡")
            //   }</Button>
            // )
            // <Button className="activation-button">{ t("激活") }</Button>
            !info ? (
              <></>
            ) : (info?.lv3Status === "IN_REVIEW" || info?.lv3Status === "SUPPLIER_IN_REVIEW") ? (
              <div className="result" style={{ "--bgcolor": "#D1840A" }}>
                <img src={IMAGE.result1} alt="" />
                <div className="result_desc">
                  <p style={{ "--fw": 600 }}>{ t("我們正在審核您的身份資料") }</p>
                  <p style={{ "--color": "#FFE1A6" }}>{ t("一旦通過將立即爲您寄出實體卡") }</p>
                </div>
              </div> 
            ) : info?.lv3Status === "REJECTED" ? (
              <div className="result" style={{ "--bgcolor": "#212121" }}>
                <img src={IMAGE.result2} alt="" />
                <div className="result_desc">
                  <p style={{ "--fw": 600 }}>{ t("身份驗證不通過") }</p>
                  {
                    !!info?.lv3ErrorMsg && <p style={{ "--color": "rgba(255, 255, 255, 0.65)" }}>{ info?.lv3ErrorMsg }</p>
                  }
                </div>
                <Button className="btn" onClick={() => history.push("/openApply")}>{ t("重新提交") }</Button>
              </div>
            ) : data.status === 'PASSED' && data2.status === "CREATED" ? (
              <Button className="activation-button" onClick={() => history.push("/activation")}>{ t("激活") }</Button>
            ) : (
              <Button className="activation-button" onClick={() => {
                if (info.cardFeeStatus === "NOT_PAID") {
                  history.push("/pay")
                } else if (!info.hasPaymentPassword) {
                  history.push("/openPin")
                } else if (data?.status !== "CREATED" && data?.status !== "PASSED") {
                  history.push("/openApply")
                } else if (!info?.lv3Status) {
                  history.push("/openApply")
                }
              }}>{ t("繼續開戶") }</Button>
            )
          }
        </>
      }
      {
        !!list && list.length > 0 && <>
          <div  className="line"></div>
          <p className="session-title">{t("最近的交易")}</p>
          <div className="recent-list">
          {
            list.map((item, index) => {
              return <div key={index} className="recent-item">
                <div className="item-content-div">
                  <p className="name">{ item.desc }</p>
                  <p className="amount" style={{ color: parseFloat(item.amount) > 0 ? "#10C29F" : "#212121" }}>{item.amount === "-0" ? "0" : DelNum(item.amount)}</p>
                </div>
                <div className="item-content-div" style={{ marginTop: '8px' }}>
                  <p className="time">{ moment(item.time * 1000).format("YYYY-MM-DD HH:mm") }</p>
                  {
                    item.status === "PENDING" && <p className="status">{t("处理中")}</p>
                  }
                  {
                    item.status === "DECLINED" && <p className="status" style={{ color: 'red' }}>{t('已拒绝')}</p>
                  }
                  {
                    item.status === "FAILED" && <p className="status" style={{ color: 'red' }}>{t('交易失败')}</p>
                  }
                  {
                    item.status === "COMPLETED" && <p className="status" style={{ color: 'green' }}>{t('已完成')}</p>
                  }
                </div>
              </div>
            })
          }
          </div>
        </>
      }
      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        bodyClassName="add-founds-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => setVisible(false)} />
        <p className="title">{t("充值")}</p>
        <div className="menu-list">
        {
          ['100', '500', '1000', ''].map((item, index) => {
            return <div key={index} className="menu-item" style={{ borderColor: menuIndex === index ? '#D1840A' : 'transparent' }} onClick={() => {
              setMenuIndex(index)
              if (index < 3) {
                setAmount2(item)
                setKeyboardVisible(false)
              } else {
                setKeyboardVisible(true)
              }
            }}>
              {
                item !== '' ? <>
                  <p className="t1">{`${item}USD`}</p>
                  <p className="t2">{`${item}USDT`}</p>
                </> : <>
                {
                  menuIndex === index ? <p className="amount">{ `${amount}` }<span style={{ visibility: keyboardVisible ? 'visible' : 'hidden' }}></span></p> : <p className="placehorder-txt">{t("自定义金额")}</p>
                }
                </>
              }
            </div>
          })
        }
        </div>
        {/* <p className="subtitle">请输入充值金额</p>
        <div className="input-div">
          <p className="amount-div" onClick={() => setKeyboardVisible(true)}>{ `$${amount}` }<span style={{ visibility: keyboardVisible ? 'visible' : 'hidden' }}></span></p>
          {
            assets.length > 0 && <p className="max-button" onClick={() => {
              const _balance = Math.max(assets[assetIndex].balance - 0.01, 0)
              const _max = Math.floor(_balance * parseFloat(assets[assetIndex].price) * 100) / 100 
              setAmount(_max.toString())
            }}>全部</p>
          }
        </div>
        {
          assets.length > 0 && <p className="content">{`最小充值金额: ${assets[assetIndex]?.minRecharge}${assets[assetIndex].asset}`}</p>
        } */}
        {
          assets.length > 0 && <div className="pay-div" onClick={() => {
            setKeyboardVisible(false)
            setVisible2(true)
          }}>
            <img src={assets[assetIndex].token.icon} alt="" className="img1" />
            <div style={{ flex: '1' }}>
              <p className="t1">{t("支付币种")}</p>
              <p className="t2" style={{marginTop: '2px'}}>{ `${Math.ceil(parseFloat(menuIndex < 3 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100} ${assets[assetIndex].asset}` }</p>
            </div>
            <div style={{alignItems: "flex-end"}}>
              <p className="t1">{t("余额")}</p>
              <p className="t1" style={{marginTop: '4px'}}>{ DelNum(assets[assetIndex].balance) }</p>
            </div>
            {/* <img src={IMAGE.clickTag} alt="" className="img2" /> */}
          </div>
        }
        {
          assets.length > 0 && <Button className="confirm-button" disabled={
            (menuIndex === 3 && Number(amount || "0") < 100) || 
            Math.ceil(parseFloat(menuIndex < 3 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100 < assets[assetIndex]?.minRecharge || 
            assets[assetIndex].balance - Math.ceil(parseFloat(menuIndex < 3 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100 < 0} onClick={() => toPay()}>{`${t("充值")}${menuIndex < 3 ? amount2 : amount === '' ? '-' : amount}USDT`}</Button>
        }
        {/* {
          assets.length > 0 && <p className="question-button" onClick={() => setEmailVisible(true)}>遇到问题?</p>
        } */}
      </Popup>
      <Popup
        visible={visible2}
        onMaskClick={() => setVisible2(false)}
        bodyClassName="add-founds-token-popup"
      >
        <div className="token-list">
        {
          assets.map((item, index) => {
            return <div className="token-item" key={index} onClick={() => {
              setAssetIndex(index)
              setAmount('100')
              setVisible2(false)
            }}>
              <img src={item.token.icon} alt="" className="icon" />
              <p className="name">{ item.asset }</p>
              {
                assetIndex === index && <img src={IMAGE.select_icon} alt="" className="select-icon" />
              }
            </div>
          })
        }
        </div>
      </Popup>
      <NumberKeyboard
        visible={keyboardVisible}
        onClose={() => setKeyboardVisible(false)}
        onInput={(value) => setAmount(v => countDecimalPlaces() < 3 ? v + value : v)}
        onDelete={() => setAmount(v => v.slice(0, v.length - 1))}
        confirmText={t("完成")}
        customKey={amount.includes(".") ? [] : ['.']}
      />
      <Modal visible={emailVisible} bodyClassName="card-modal" content={
        <div className="modal-content-div">
          <p className="title">{t("联系我们")}</p>
          <p className="content">{`${t("微信号：")}${supportEmail}`}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setEmailVisible(false)}>{t("取消")}</Button>
            <CopyToClipboard text={supportEmail} onCopy={() => {
              setEmailVisible(false)
              Toast.show({
                icon: "success",
                content: t("复制成功"),
              })
            }}>
              <Button className="button confirm">{t("复制微信号")}</Button>
            </CopyToClipboard>
          </div>
        </div>
      } />
      <Modal visible={cardFeeOpen} bodyClassName="card-fee-modal" content={
        <div className="modal-content-div">
          <p className="title">{t("支付开卡费")}</p>
          <p className="content">{t("请先支付 408 USDT 作为开卡费用，完成支付后才能申请KYC验证。")}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setCardFeeOpen(false)}>{t("取消")}</Button>
            <Button className="button confirm" onClick={() => {
              onDeduct()
              setCardFeeOpen(false)
            }}>{t("确定")}</Button>
          </div>
        </div>
      } />
      <Popup
        visible={transferVisible}
        bodyClassName="transfer-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => {
          setTransferEmail('')
          setTransferNumber('')
          setTransferAmount("")
          setSelfNumber("")
          setTransferVisible(false)
        }} />
        <p className="title">{t("转账到其他卡")}</p>
        <div className="session-div">
          <p className="name">{t("转账卡号")}</p>
          <div className="input-div">
            <div className="input-container">
              <Input placeholder={t("你的银行卡号")} value={selfNumber} className="input" onChange={value => setSelfNumber(value)} />
            </div>
          </div>
        </div>
        <div className="session-div">
          <p className="name">{t("收款邮箱")}</p>
          <div className="input-div">
            <div className="input-container">
              <Input placeholder={t("收款人邮箱")} value={transferEmail} className="input" onChange={value => setTransferEmail(value)} />
            </div>
          </div>
        </div>
        <div className="session-div">
          <p className="name">{t("收款卡号")}</p>
          <div className="input-div">
            <div className="input-container">
              <Input placeholder={t("收款人的银行卡号")} value={transferNumber} className="input" onChange={value => setTransferNumber(value)} />
            </div>
          </div>
        </div>
        <div className="session-div">
          <p className="name">{t("金额")}</p>
          <div className="input-div">
            <div className="input-container">
              <p className="symbol">$</p>
              <Input placeholder={t("数量")} value={transferAmount} className="input" onChange={value => setTransferAmount(value)} />
              <p className="all-button" onClick={() => setTransferAmount(Math.floor(amountUSDT * 100) / 100)}>{t("全部")}</p>
            </div>
          </div>
        </div>
        <div className="session-div">
          <p className="name">{t("登录密码")}</p>
          <div className="input-div">
            <div className="input-container">
              <Input placeholder={t("登录密码")} value={parssword} type='password' className="input" onChange={value => setPassword(value)} />
            </div>
          </div>
        </div>
        <div className="session-div">
          <p className="name">{t("邮箱验证码")}</p>
          <div className="input-div">
            <div className="input-container">
              <Input placeholder={t("验证码")} value={withdrawCode} className="input" onChange={value => setWithdrawCode(value)} />
              {
                timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha()}>{t("发送")}</p> : <p className="resend-button resend-button-disable">{`${t("重新发送")} ${timeOut}s`}</p>
              }
            </div>
          </div>
        </div>
        <p className="txt" style={{ marginTop: '24px' }}><span>{t("余额")}:</span> {`${DelNum(amountUSDT)}`}</p>
        <p className="txt"><span>{t("最小")}:</span> {`>2`}</p>
        <Button className="confirm-button2" disabled={!transferEmail || !transferNumber || !transferAmount || Number(transferAmount) <= 2 || !selfNumber || !parssword || !withdrawCode } onClick={() => onTransfer()}>{t("转账")}</Button>
      </Popup>
      <Tabbar selIndex={0} />
    </div> : <div className="card-page">
      <div className="navbar">
        <p className="title">{t("金猴卡")}</p>
      </div>
      <img src={IMAGE.cardImage} alt="" className="card-image" />
      <Tabbar selIndex={0} />
    </div>
  )
}
export default withRouter(IndexPage)