/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Toast } from "antd-mobile"
import { DelNum } from "@/tool"
import { IMAGE } from "@/assets"
import { get, post } from "@/service"
import moment from 'moment'
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [list, setList] = useState([])

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await get("card/quota")
        Toast.clear()
        setData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  const withdraw = async () => {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      await post("card/quota/withdraw")
      Toast.clear()
      Toast.show({
        icon: "success",
        content: t("提取成功"),
      })
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("card/quota/record", {
          page: 1,
          pageSize: 500
        })
        Toast.clear()
        setList(resp.content)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, []) 

  return (
    <div className="manager-quota-page">
      <div className="navbar">
        <img src={IMAGE.back_white} alt="" onClick={() => history.goBack()} />
        <p>{t("数字信用管理")}</p>
      </div>
      <div className='content'>
        <p className="title">{t("可用额度")}</p>
        <p className='amount'>{`$${!!data ? DelNum(data.balance) : "-"}`}</p>
        <div className='content_row'>
          <div className='content_row_item'>
            <p className='t1'>{t("信用额度")}</p>
            <p className='t2'>{`$${!!data ? DelNum(data.consumeQuota) : "-"}`}</p>
            <Button className='button' onClick={() => history.push(`/promoteQuota/${data?.authorizationTimes || 0}`)}>{t("提升信用")}</Button>
            <p className='t3'>{`${t("最高可达")}$${!!data ? DelNum(data.maxConsumeQuota) : "-"}`}</p>
          </div>
          <div className='content_row_item'>
            <p className='t1'>{t("當月額度")}</p>
            <p className='t2'>{`$${!!data ? DelNum(data.monthlyQuota) : "-"}`}</p>
            <Button className='button' disabled={Number(data?.monthlyQuota || "0") === 0} onClick={() => withdraw()}>{t("申請使用")}</Button>
            <p className='t3'>{t("T+2到账")}</p>
          </div>
        </div>
        <div className='content_row'>
          <div className='content_row_item'>
            <p className='t1'>{t("剩余额度")}</p>
            <p className='t2' style={{ textAlign: 'center' }}>{`$${!!data ? DelNum(data.remainingQuota) : "-"}`}</p>
          </div>
          <div className='content_row_item'>
            <p className='t1'>{t("已用额度")}</p>
            <p className='t2' style={{ textAlign: 'center' }}>{`$${!!data ? DelNum(data.usedQuota) : "-"}`}</p>
          </div>
          <div className='content_row_item'>
            <p className='t1'>{t("待还额度")}</p>
            <p className='t2' style={{ textAlign: 'center' }}>{`$${!!data ? DelNum(data.repaymentQuota) : "-"}`}</p>
          </div>
        </div>
      </div>
      <Button className='promote-button' onClick={() => history.push(`/promoteQuota/${data?.authorizationTimes || 0}`)}><img src={IMAGE.arrowTop} alt="" />{t("信用额度申请")}</Button>
      {
        list.length > 0 && (
          <div className='list'>
            <div className='head'>
              <img src={IMAGE.line1} alt='' />
              <p>{t("额度记录")}</p>
              <img src={IMAGE.line2} alt='' />
            </div>
            <div className='datas'>
            {
              list.map((item, index) => {
                return (
                  <div className='item' key={index}>
                    {/* <img src={item.type === "WITHDRAW" ? IMAGE.quotaBottom : IMAGE.quotaTop} alt="" className='icon' /> */}
                    <div className='item_div'>
                      <div className='item_row'>
                        <p className='t1'>{item.type === "WITHDRAW" ? t("已用額度") : t("额度提升")}</p>
                        <p className='t2'>{`${item.type === "WITHDRAW" ? "-" : "+"}$${DelNum(item.amount)}`}</p>
                      </div>
                      <div className='item_row'>
                        <p className='t3'>{moment(item.createTime * 1000).format("YYYY-MM-DD HH:mm")}</p>
                        <p className='t3'>{item.status === "PROGRESSING" ? t("审核中") : item.status === "ERROR" ? t("已拒绝") : ""}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            </div>
          </div>
        )
      }
    </div>
  )
}
export default withRouter(ManagerAddressPage)